export default Object.freeze({
  BASIC: 'basic',
  SINGLE_SELECT: 'single_select',
  MULTI_SELECT: 'multi_select',
  ORDERED: 'ordered',
  MATRIX: 'matrix',
  GEOLOCATION: 'geolocation',
  IMAGE: 'image',
  BUDGET: 'budget',
  DROPDOWN: 'dropdown',
  NUMERIC: 'numeric',
  SLIDER: 'slider',
  RATING: 'rating',
  BUDGET_OPEN: 'budget_open',
});
