// Libraries
import React from 'react';
import { TileLayer } from 'react-leaflet';

export const StreetMapLayer = (props) => (
  <TileLayer
    detectRetina
    {...props}
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  />
);
